import React from 'react';
import {Link} from 'react-router-dom'
import {FaChevronRight, FaFacebookSquare, FaTwitterSquare, FaLinkedin} from 'react-icons/fa';


let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}



const Footer = () => {

  return (
    <section className='section-cover footer-cover' style={{ backgroundImage: `linear-gradient(#013281,#013281), url("./images/4.jpg")`,backgroundAttachment:'fixed',backgroundSize:'cover', backgroundPosition:'center'
    }}>
      <div className='footer-content'>





             <div className='footer-inner-content'>
                    <h3>Quick Links</h3>
                    <div className="footer-links-cover">
                       

                         <div className='footer-link'>
                              <Link to='/' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Home</p></Link> 
                        </div>
                        {/* <div className='footer-link'>
                              <Link to='#' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>About Us</p></Link> 
                        </div> */}

                        <div className='footer-link'>
                              <Link to='products' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Our Products</p></Link> 
                        </div>
                         <div className='footer-link'>
                                <Link to='services' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Our Services</p></Link>
                        </div>
                        <div className='footer-link'>
                                <Link to='gallery' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Gallery</p></Link>
                        </div>
                         <div className='footer-link'>
                                <Link to='clients' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Our Clients</p></Link>
                        </div>

  
                    </div>

             </div>

            

             {/* <div className='footer-inner-content'>
                    <h3>Our Services</h3>
                    <div className="footer-links-cover">
                      

                         <div className='footer-link'>
                              <Link to='telecom' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Telecom & Networking Services</p></Link> 
                        </div>

                        <div className='footer-link'>
                              <Link to='power' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Power Line Design & Construction</p></Link> 
                        </div>

                          <div className='footer-link'>
                                <Link to='road-construction' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Road Construction & Maintenance</p></Link>
                        </div>

                        <div className='footer-link'>
                              <Link to='support' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Support & Managed Services</p></Link> 
                        </div>
                          <div className='footer-link'>
                              <Link to='maintenance' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Preventive Maintenance</p></Link> 
                        </div>

                        <div className='footer-link'>
                            <Link to='emergency-response' onClick={scrollFunc}>   <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Emergency Response</p></Link> 
                        </div>

                         <div className='footer-link'>
                            <Link to='building-construction' onClick={scrollFunc}>   <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Building Construction</p></Link> 
                        </div>
                      
                      
                    </div>

             </div> */}

             <div className='footer-inner-content'>
<div className='footer-location'>
                      <h3>Location</h3>
                      <p>
                    Plot 6, Kataza-Bunyonyi Lane, Bugolobi<br></br>
P. O. Box 101809, Kampala - Uganda
                        </p>

                    </div>

                    <div className='footer-contacts'>
                      <h3>Call Us On</h3>
                      <p>
                      +256782 757226<br></br>
+256756 027059<br></br>
+256773 084476
                      </p>

                    </div>
                     

                   
                      

             </div>

          <div className='footer-inner-content'>


                     {/* <div className='footer-email'>
                      <h3>Write To Us At</h3>
                   <Link to='#contact' onClick={scrollFunc} className='email-link'>  <p>info@pinnacleconceptsltd.com</p></Link> 
                      
                      </div> */}
                      <div className='footer-social-icons'>
                         
                              
                        
                      </div>
                      <div className='footer-social-icons'>
                         <h3>Connect With Us On</h3>
                         <div className='footer-icons'>
                                 <span>
<a href="#" target="_blank" className='social-link'><FaFacebookSquare></FaFacebookSquare></a>
                                 </span>
                                 <span>
<a href="#" target="_blank" className='social-link'><FaTwitterSquare></FaTwitterSquare></a>
                                 </span>

                                 <span>
<a href="#" target="_blank" className='social-link'><FaLinkedin></FaLinkedin></a>
                                 </span>  
                         </div>
                        
                        
                      </div>
                       {/* <div className='footer-email'>
                      <h3>Write To Us At</h3>
                   <Link to='contact' onClick={scrollFunc} className='email-link'>  
                   <p>info@fordwichcompany.com<br></br>
fordwichcompany@gmail.com</p>
                   </Link> 
                      
                      </div> */}
                       <div className='footer-social-icons footer-social-icons-a'>
                        <Link to='inquiries' className='inquiries-link' onClick={scrollFunc}>Inquiries</Link>
                        {/* <a href="https://www.bubuexpo.com"  target='_blank'>  <img src='./images/204.jpg' className='bubu-logo'></img></a> */}
                      </div>
                     

                      {/* <div className='footer-social-icons'>
                        <a href="https://www.bubuexpo.com"  target='_blank'>  <img src='./images/204.jpg' className='bubu-logo'></img></a>
                      </div> */}

             </div>
        
        
        </div>
        <div className='footer-bottom'>
            <p>    &copy; {new Date().getFullYear()} <span className="date"></span> Fordwich Company Limited | Website Developed By <a href="https://bensitesug.com" target="_blank" className="footer-link-a" rel="noopener noreferrer">BenSites</a></p>
          </div>
    </section>
  )
}

export default Footer