import Carousel from 'react-bootstrap/Carousel';

function TelecomCarousel() {
  return (
    <Carousel indicators={false} className='services-slides'>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/1.jpg"
          alt="First slide"
        />

      </Carousel.Item>
  
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/5.jpg"
          alt="Second slide"
        />    
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/16.jpg"
          alt="Second slide"
        />    
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/22.jpg"
          alt="Second slide"
        />    
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/31.jpg"
          alt="Second slide"
        />    
      </Carousel.Item>
        <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/34.jpg"
          alt="Second slide"
        />    
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/39.jpg"
          alt="Second slide"
        />    
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/42.jpg"
          alt="Second slide"
        />    
      </Carousel.Item>
    </Carousel>
  );
}

export default TelecomCarousel;