
import React, {useEffect, useState} from 'react';

import {FaArrowRight, FaTimes} from 'react-icons/fa';



const Team = () => {
    useEffect(()=>{
document.title = "UPS Units | Fordwich Company Limited";
},[]);


  return (
    <section className='section-cover team-cover equip-cover' >
         <div className='content-container products-container'>
          
          <h2 className="home-h2 home-service-h2">UPS Units</h2>
             {/* <p className='about-intro-p'>Exotic Furniture</p> */}
         
            <div className='services-container-new'>
               
                <article className='single-service-new'>
    
              <div className='tools-slides-cover'>
                {/* <Tool1></Tool1> */}
                <img src='./images/43.jpg' class='item-image'></img>
              </div>
            {/* <h3 className='skill-h3 service-h3-new'>PC/HF/DT/002</h3> */}
          </article>
          <article className='single-service-new'>
    
              <div className='tools-slides-cover'>
                {/* <Tool1></Tool1> */}
                <img src='./images/44.jpg' class='item-image'></img>
              </div>
            {/* <h3 className='skill-h3 service-h3-new'>PC/HF/DT/003</h3> */}
          </article>
          <article className='single-service-new'>
         
              <div className='tools-slides-cover'>
                {/* <Tool2></Tool2> */}
                <img src='./images/45.jpg' class='item-image '></img>
              </div>
            {/* <h3 className='skill-h3 service-h3-new'>PC/HF/DT/004</h3> */}
          
          </article>
           <article className='single-service-new'>
    
              <div className='tools-slides-cover'>
                {/* <Tool1></Tool1> */}
                <img src='./images/42.jpg' class='item-image'></img>
              </div>
            {/* <h3 className='skill-h3 service-h3-new'>PC/HF/DT/001</h3> */}
          </article>
      </div>
         </div>
    </section>
  )
}

export default Team