
import React, {useEffect, useState} from 'react';

import {FaArrowRight, FaTimes} from 'react-icons/fa';



const Team = () => {
    useEffect(()=>{
document.title = "Laptops | Fordwich Company Limited";
},[]);


  return (
    <section className='section-cover team-cover equip-cover' >
         <div className='content-container products-container'>
          
          <h2 className="home-h2 home-service-h2">Laptops</h2>
             {/* <p className='about-intro-p'>Exotic Furniture</p> */}
         
            <div className='services-container-new'>
                <article className='single-service-new'>
    
              <div className='tools-slides-cover'>
                {/* <Tool1></Tool1> */}
                <img src='./images/5.jpg' class='item-image'></img>
              </div>
            {/* <h3 className='skill-h3 service-h3-new'>  PC/OF/CH/001</h3> */}
          </article>
                <article className='single-service-new'>
    
              <div className='tools-slides-cover'>
                {/* <Tool1></Tool1> */}
                <img src='./images/6.jpg' class='item-image'></img>
              </div>
            {/* <h3 className='skill-h3 service-h3-new'>PC/OF/CH/002</h3> */}
          </article>
          <article className='single-service-new'>
    
              <div className='tools-slides-cover'>
                {/* <Tool1></Tool1> */}
                <img src='./images/7.jpg' class='item-image'></img>
              </div>
            {/* <h3 className='skill-h3 service-h3-new'>PC/OF/CH/003</h3> */}
          </article>
          <article className='single-service-new'>
         
              <div className='tools-slides-cover'>
                {/* <Tool2></Tool2> */}
                <img src='./images/8.jpg' class='item-image'></img>
              </div>
            {/* <h3 className='skill-h3 service-h3-new'>PC/OF/CH/004</h3> */}
          
          </article>
          <article className='single-service-new'>
         
              <div className='tools-slides-cover'>
                {/* <Tool2></Tool2> */}
                <img src='./images/9.jpg' class='item-image'></img>
              </div>
            {/* <h3 className='skill-h3 service-h3-new'>PC/OF/CH/005</h3> */}
          
          </article>
          <article className='single-service-new'>
         
              <div className='tools-slides-cover'>
                {/* <Tool2></Tool2> */}
                <img src='./images/10.jpg' class='item-image'></img>
              </div>
            {/* <h3 className='skill-h3 service-h3-new'>PC/OF/CH/006</h3> */}
          
          </article>
            <article className='single-service-new'>
         
              <div className='tools-slides-cover'>
                {/* <Tool2></Tool2> */}
                <img src='./images/11.jpg' class='item-image'></img>
              </div>
            {/* <h3 className='skill-h3 service-h3-new'>PC/OF/CH/006</h3> */}
          
          </article>
            <article className='single-service-new'>
         
              <div className='tools-slides-cover'>
                {/* <Tool2></Tool2> */}
                <img src='./images/12.jpg' class='item-image'></img>
              </div>
            {/* <h3 className='skill-h3 service-h3-new'>PC/OF/CH/006</h3> */}
          
          </article>
            <article className='single-service-new'>
         
              <div className='tools-slides-cover'>
                {/* <Tool2></Tool2> */}
                <img src='./images/13.jpg' class='item-image'></img>
              </div>
            {/* <h3 className='skill-h3 service-h3-new'>PC/OF/CH/006</h3> */}
          
          </article>
            <article className='single-service-new'>
         
              <div className='tools-slides-cover'>
                {/* <Tool2></Tool2> */}
                <img src='./images/14.jpg' class='item-image'></img>
              </div>
            {/* <h3 className='skill-h3 service-h3-new'>PC/OF/CH/006</h3> */}
          
          </article>
            <article className='single-service-new'>
         
              <div className='tools-slides-cover'>
                {/* <Tool2></Tool2> */}
                <img src='./images/15.jpg' class='item-image'></img>
              </div>
            {/* <h3 className='skill-h3 service-h3-new'>PC/OF/CH/006</h3> */}
          
          </article>
        
      </div>
         </div>
    </section>
  )
}

export default Team