import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import PowerCarousel from '../components/PowerCarousel';
import TelecomCarousel from '../components/TelecomCarousel';
import RoadCarousel from '../components/RoadCarousel';
import SupportCarousel from '../components/SupportCarousel';
import MaintCarousel from '../components/Maintenance';
import EmergencyCarousel from '../components/Emergency';
import BuildingsCarousel from '../components/BuildingsCarousel';
import Plumbing from '../components/plumbing';
import AC from '../components/AC';
import Painting from '../components/Painting';
import Doors from '../components/Doors';
import Al from '../components/Al';
import Carpentry from '../components/Carpentry';
import Tile from '../components/Tile';
import Cleaning from '../components/Cleaning';
import Supply from '../components/Supply';
import GS from '../components/GS';


const Services = () => {

useEffect(()=>{
document.title = "Gallery | Fordwich Company Limited";

},[]);

let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}


  return (
    <section className='section-cover services-cover' >
      <h2 className="home-h2 services-h2 home-service-h2">Gallery</h2>
          <section className="home-services services-page">
              
               <div className="home-services-center services-center1">
                <h4 className="gallery-h4">Fordwich Team training staff of National Teachers College Kabale on how to use ICT equipment at the digital hub. <em>This project was funded by Enabel.</em></h4>
                   

                    <article className="home-service home-service1" id="engi">
                      <img src="images/72.jpg" alt="image" className="home-service-image home-service-image1"></img>
                      <p className="home-service-p services-page-p services-page-p1">Installation of Projector Screen</p>
        
                    </article>

                   <article className="home-service home-service1" id="proj">
                      <img src="images/71.jpg" alt="image" className="home-service-image home-service-image1"></img>
                      <p className="home-service-p services-page-p services-page-p1">Training on how to use the Digital Camera</p>
                   
                    </article>

                    <article className="home-service home-service1" id="proj">
                        <img src="images/70.jpg" alt="image" className="home-service-image home-service-image1"></img>
                        <p className="home-service-p services-page-p services-page-p1">Training on how to use Computer Repair Kits</p>
                    
                    </article>

                    <article className="home-service home-service1" id="proj">
                        <img src="images/73.jpg" alt="image" className="home-service-image home-service-image1"></img>
                        <p className="home-service-p services-page-p services-page-p1">Smart Board Installed at National Teachers College – Kabale</p>
                    
                    </article>

               </div>


        </section>

    </section>
  )
}

export default Services