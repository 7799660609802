import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import WhoWeAre from './pages/WhoWeAre';
import Team from './pages/Team';
import CoatHangers from './pages/Coat-Hangers';
import SofaSets from './pages/Sofa-Sets';
import Equipment from './pages/Equipment';
import Products from './pages/Office-Furniture';
import HomeFurniture from './pages/Home-Furniture';
import Beds from './pages/Beds';
import Wardrobes from './pages/Wardrobes';
import Services from './pages/Services';
import Clients from './pages/NewClients';
import Gallery from './pages/NewGallery';
import Certification from './pages/Certification';
import Contact from './pages/Contact';
import Telecom from './pages/Telecom';
import Power from './pages/Power';
import DesktopComputers from './pages/Water-Dispensers';
import OfficeSofas from './pages/Office-Sofas';
import Desks from './pages/Office-Desks';
import OfficeChairs from './pages/Office-Chairs';
import CabinetsShelves from './pages/Office-Cabinets';
import BoardroomTables from './pages/Boardroom-Tables';
import DiningTables from './pages/Dining-Tables';
import CenterTable from './pages/Center-Table';
import TVStands from './pages/TV-Stands';
import KitchenCabinets from './pages/Kitchen-Cabinets';
import CB from './pages/Curtains-Blinds';
import Curtains from './pages/Curtains';
import MN from './pages/Mosquito-Nets';
import CR from './pages/Curtain-Rods';
import VB from './pages/Vertical-Blinds';
import RB from './pages/Roller-Blinds';
import Venetian from './pages/Venetian-Blinds';
import Decor from './pages/Decor';
import Error from './pages/Error';
import Footer from "./components/Footer";
import Nav from "./components/Nav";
import TopSection from "./components/TopSection";
import WhatsAppIcon from "./components/WhatsAppIcon";
import BackToTop from "./components/BackToTop";

function App() {
  return (
    <BrowserRouter>
    <TopSection></TopSection>
      <Nav></Nav>
       <Routes>
            <Route path='/' element={<Home></Home>}></Route>
            <Route path='about' element={<WhoWeAre></WhoWeAre>}></Route>
            <Route path='our-team' element={<Team></Team>}></Route>
            <Route path='services' element={<Services></Services>}></Route>
            <Route path='clients' element={<Clients></Clients>}></Route>
            <Route path='gallery' element={<Gallery></Gallery>}></Route>
             <Route path='equipment' element={<Equipment></Equipment>}></Route>
             
            <Route path='certifications' element={<Certification></Certification>}></Route>
             <Route path='inquiries' element={<Contact></Contact>}></Route>
             <Route path='*' element={<Error></Error>}></Route>
             {/* Footer Services Links */}
 <Route path='telecom' element={<Telecom></Telecom>}></Route>
 <Route path='power' element={<Power></Power>}></Route>
 <Route path='desktop-computers' element={<DesktopComputers></DesktopComputers>}></Route>
 <Route path='laptops' element={<CoatHangers></CoatHangers>}></Route>
  <Route path='printers' element={<OfficeSofas></OfficeSofas>}></Route>
  <Route path='cctv-cameras' element={<Desks></Desks>}></Route>
  <Route path='digital-cameras' element={<OfficeChairs></OfficeChairs>}></Route>
  <Route path='smart-boards' element={<CabinetsShelves></CabinetsShelves>}></Route> 
   <Route path='projectors' element={<BoardroomTables></BoardroomTables>}></Route>
   <Route path='products' element={<Products></Products>}></Route>  
   <Route path='home-furniture' element={<HomeFurniture></HomeFurniture>}></Route>
   <Route path='projector-screens' element={<SofaSets></SofaSets>}></Route>  
  <Route path='ups-units' element={<DiningTables></DiningTables>}></Route>      
  <Route path='software' element={<Beds></Beds>}></Route>        
  <Route path='tablets-phones' element={<Wardrobes></Wardrobes>}></Route> 
 <Route path='keyboard-mouse' element={<CenterTable></CenterTable>}></Route>
 <Route path='tv-screens' element={<TVStands></TVStands>}></Route> 
 <Route path='kitchen-cabinets' element={<KitchenCabinets></KitchenCabinets>}></Route> 
 <Route path='curtains-blinds' element={<CB></CB>}></Route>  
<Route path='curtains' element={<Curtains></Curtains>}></Route>
<Route path='mosquito-nets' element={<MN></MN>}></Route>  
<Route path='curtain-rods' element={<CR></CR>}></Route>  
<Route path='vertical-blinds' element={<VB></VB>}></Route>
<Route path='roller-blinds' element={<RB></RB>}></Route>  
<Route path='venetian-blinds' element={<Venetian></Venetian>}></Route>
<Route path='decor-accessories' element={<Decor></Decor>}></Route>      
       </Routes>
       <WhatsAppIcon></WhatsAppIcon><BackToTop></BackToTop>
       <Footer></Footer>
    </BrowserRouter>
  );
}

export default App;
