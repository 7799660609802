
import React, {useEffect, useState} from 'react';

import {FaArrowRight, FaTimes} from 'react-icons/fa';



const Team = () => {
    useEffect(()=>{
document.title = "TV Screens | Fordwich Company Limited";
},[]);


  return (
    <section className='section-cover team-cover equip-cover' >
         <div className='content-container products-container'>
          
          <h2 className="home-h2 home-service-h2">TV Screens</h2>
             {/* <p className='about-intro-p'>Exotic Furniture</p> */}
         
            <div className='services-container-new'>
              <article className='single-service-new'>
    
              <div className='tools-slides-cover'>
                {/* <Tool1></Tool1> */}
                <img src='./images/93.jpg' class='item-image item-image-a'></img>
              </div>
            {/* <h3 className='skill-h3 service-h3-new'>Office Desks</h3> */}
          </article>
          <article className='single-service-new'>
    
              <div className='tools-slides-cover'>
                {/* <Tool1></Tool1> */}
                <img src='./images/94.jpg' class='item-image item-image-a'></img>
              </div>
            {/* <h3 className='skill-h3 service-h3-new'>Office Desks</h3> */}
          </article>
                <article className='single-service-new'>
    
              <div className='tools-slides-cover'>
                {/* <Tool1></Tool1> */}
                <img src='./images/65.jpg' class='item-image item-image-a'></img>
              </div>
            {/* <h3 className='skill-h3 service-h3-new'>Office Desks</h3> */}
          </article>
                <article className='single-service-new'>
    
              <div className='tools-slides-cover'>
                {/* <Tool1></Tool1> */}
                <img src='./images/66.jpg' class='item-image item-image-a'></img>
              </div>
            {/* <h3 className='skill-h3 service-h3-new'>Office Desks</h3> */}
          </article>
          <article className='single-service-new'>
    
              <div className='tools-slides-cover'>
                {/* <Tool1></Tool1> */}
                <img src='./images/67.jpg' class='item-image item-image-a'></img>
              </div>
            {/* <h3 className='skill-h3 service-h3-new'>Office Desks</h3> */}
          </article>
          <article className='single-service-new'>
         
              <div className='tools-slides-cover'>
                {/* <Tool2></Tool2> */}
                <img src='./images/68.jpg' class='item-image item-image-a'></img>
              </div>
            {/* <h3 className='skill-h3 service-h3-new'>Office Chairs</h3> */}
          
          </article>
      </div>
         </div>
    </section>
  )
}

export default Team