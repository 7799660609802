
import React, {useEffect, useState} from 'react';

import {FaArrowRight, FaTimes} from 'react-icons/fa';



const Team = () => {
    useEffect(()=>{
document.title = "Digital Cameras | Fordwich Company Limited";
},[]);


  return (
    <section className='section-cover team-cover equip-cover' >
         <div className='content-container products-container'>
          
          <h2 className="home-h2 home-service-h2">Digital Cameras</h2>
             {/* <p className='about-intro-p'>Exotic Furniture</p> */}
         
            <div className='services-container-new'>
              <article className='single-service-new'>
    
              <div className='tools-slides-cover'>
                {/* <Tool1></Tool1> */}
                <img src='./images/33.jpg' class='item-image'></img>
              </div>
            {/* <h3 className='skill-h3 service-h3-new'>PC/OF/OCC/003</h3> */}
          </article>
          <article className='single-service-new'>
    
              <div className='tools-slides-cover'>
                {/* <Tool1></Tool1> */}
                <img src='./images/77.jpg' class='item-image'></img>
              </div>
            {/* <h3 className='skill-h3 service-h3-new'>PC/OF/OCC/003</h3> */}
          </article>
          <article className='single-service-new'>
              <div className='tools-slides-cover'>
                {/* <Tool3></Tool3> */}
                <img src='./images/32.jpg' class='item-image'></img>
              </div>
            {/* <h3 className='skill-h3 service-h3-new'>PC/OF/OCC/002</h3>         */}
          </article>
                <article className='single-service-new'>
              <div className='tools-slides-cover'>
                {/* <Tool3></Tool3> */}
                <img src='./images/31.jpg' class='item-image'></img>
              </div>
            {/* <h3 className='skill-h3 service-h3-new'>PC/OF/OCC/001</h3>         */}
          </article>
          
            
              
      </div>
         </div>
    </section>
  )
}

export default Team