import React, {useEffect} from 'react'
import CarouselFade from '../components/HomeCarousel';
import OfficeFurniture from '../components/Office-Furniture';
import HomeFurniture from '../components/Home-Furniture';
import CurtainsBlinds from '../components/Curtains-Blinds';
import DecorAccessories from '../components/Decor-Accessories';
import {Link} from 'react-router-dom';
import GoalsCarousel from '../components/GoalsCarousel';
import {FaCalendarPlus,FaHandshake,FaCheck,FaQuoteLeft, FaCertificate, FaUsers, FaFileAlt} from 'react-icons/fa';

let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}

const Home = () => {

  useEffect(()=>{
document.title = "Fordwich Company Limited | We Meet Your Expectations";

},[]);

  return (
    <>
    <section className='home-slider' >
      <CarouselFade></CarouselFade>
    </section>
    {/* <section className='section-cover bg-cover'>
        <div className='content-container content-container-a content-container-b'>
           
          <div className='circle1'>
          </div>
          <div className='circle2'>
          </div>
            <div className='home-info'>
          <h2 className="home-h2">Who We Are</h2>
          <div className='line1'>
                    <div className='line2'></div>
          </div>

                 <p className='home-p'><strong>Pinnacle Concepts Limited</strong> brings you great furniture for your office and home. 
                 We are one of the leading furniture suppliers in Uganda and we are here to furnish your dreams. We have the furniture you need! 
                 So browse our vast collection of furniture such as chairs, sofas, work stations, coffee tables, beds, dining tables and so much more. One thing you can be sure of is top of the line quality.

</p>

<p className='home-p'>We are composed of people from all parts of the region, giving us a wonderful breadth of cultural backgrounds, depth of talent and human potential.  We aim to build on the diversity of our people and dedicate ourselves to achieving a work environment that encourages and values the contributions of each person.</p>

           </div>
           <div className='bubu-logo-cover'>
                   <a href="https://www.bubuexpo.com"  target='_blank'>  <img src='./images/204.jpg' className='bubu-logo'></img></a>
           </div>
        </div>

    </section> */}
    <section className='section-cover bg-cover'>
        <div className='content-container content-container-a'>
          {/* <div className='circle1'>
          </div>
          <div className='circle2'>
          </div> */}
          {/* <h2 className="home-h2">Who We Are</h2>
          <div className='line1'>
                    <div className='line2'></div>
          </div> */}

                 <p className='home-p'>
               <strong>Fordwich Company Ltd</strong> is a prominent and duly registered technology procurement company that with extensive experience in supply, installation of ICT and Electronic Equipment. The company also provides training on ICT systems installation, maintenance and troubleshooting.
</p>

<p className='home-p'>
  Our happy clients include; Government Ministries & Departments, Non-Governmental Organizations, Places of Worship, Schools and Universities, Financial Institutions and Private Individuals. We’re here to meet your needs!
  </p>
  {/* <p className='home-p'>
  We are proactive in finding solutions for our clients that best match their goals. We understand that lasting relationships are the lifeblood of our business so we pay personal attention to our clients. More so, we remain true to our founding values of quality, honesty and hard work. We “do the right thing” and our business is based on trust. Our company is consistently associated with the high standards of service, quality, personal attention to clients, and integrity.
  </p> */}

  <p className='home-p'>
    <strong>Fordwich company Ltd</strong> is run by a vibrant team under the leadership of two directors named Lubowa David Kintu and Namujuzi Josephine. Our team is comprised of individuals who have rich and diverse professional, social, and philosophical backgrounds.

Over the years, the team has helped us effectively, and thoughtfully, find solutions that have sustained our company. We identify the opportunities that others may not perceive, and the challenges that need to be addressed to ensure our long-term success.

More so, we consider our diversity as an integral part of our business culture and values.
  </p>

 
        </div>

    </section>
   

    
 {/* <section className='section-cover section-cover1'>
       <div className='content-container goals-container'>
           <article className='goals-text'>
              <h2 className='home-h2 home-service-h2 goals-h2'>Our Scope</h2>
              <div className='line1'></div>
              <div className='goals-list goals-list1'>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>More and more of our clients are turning to us for our distinctive ability to implement
innovative project management techniques and to serve as a reliable provider of
knowledge-driven solutions for their complex construction and Supply projects.
</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description goal-description1'>We with our pool of professionals, our scope of services is broadly defined under four categories. These include field studies (baseline studies and monitoring and evaluation), research (proposal development, questionnaire design, data collection, data analysis, report writing and dissemination of results, data management (database design, database maintenance, readiness assessment and development), capacity building (trainings in research, data management, field studies) and tax advisory.
                 <br></br> <br></br> <strong>The Eight Investments Company Limited</strong> focuses on small and medium sized enterprises. Our economy is characterized by the small and medium-sized businesses that are major force behind the creation of employment and growth. Through our value statement, we are very certain that this cohort of enterprises would greatly benefit from our range of services.</p>
                     </div>

<Link to="who-we-are" className='home-btn' onClick={scrollFunc}>
         More About Us
         </Link>
                </div>
                   

           </article>

           <article className='goals-slides'>
               <GoalsCarousel></GoalsCarousel>
               <img src='images/16.jpg' className='scope-image'></img>

           </article>




       </div>

  </section> */}

   {/* <section className='home-service-cover'>
    <h2 className='home-h2 home-service-h2'>Why Choose Us?</h2>
     <h2 className='home-h2 home-service-h2'>What Makes Us Unique?</h2>
    <div className='line1'></div>
   
    <div className='home-services-container '>
      <div className='about-img-container'>
      <img src='./images/' alt='' className='about-img'></img>
   </div>
       <div className='home-services-data'>
       <div className='home-services-info '>

        <div className='home-service-box'>
            <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCheck></FaCheck></span>
          </div>
          <h3 className='home-service-title'>Quality</h3>
          <p>We provide quality products and services and promise only what we can deliver and deliver on what we promise.</p>

        </div>
       
        <div className='home-service-box'>
            <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaFileAlt></FaFileAlt></span>
          </div>
          <h3 className='home-service-title'>Professionalism</h3>
          <p>No compromise to professional standards, committed to whatever decisions we take and accept the consequences.</p>

        </div>
        <div className='home-service-box'>
          <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaHandshake></FaHandshake></span>
          </div>
          <h3 className='home-service-title'>Partnership</h3>
          <p>We engage with responsible business partners and suppliers.</p>
        </div>
<div className='home-service-box'>
          <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCalendarPlus></FaCalendarPlus></span>
          </div>
          <h3 className='home-service-title'>Durability</h3>
          <p>
           We are a market leader for durable and cost effective medical equipment in Uganda.
            </p>
        </div>

 <div className='home-service-box'>
           <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCertificate></FaCertificate>  </span>
          </div>
          <h3 className='home-service-title'>Trust</h3>
          <p>We have a proven track record with large database of corporate clients.</p>
        </div>

    </div>
        <Link to="services" className='home-btn'>
         Our Services
         </Link>


        </div>


    </div>



  </section> */}
   
     <section className='home-service-cover'>
    <h2 className='home-h2 home-service-h2'>Why Choose Us?</h2> 
    <div className='home-services-container '>
     
       <div className='home-services-data'>
       <div className='home-services-info '>

        <div className='home-service-box'>
            <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaHandshake></FaHandshake></span>
          </div>
          <h3 className='home-service-title'>Passion</h3>
          <p>We are passionate about our work and our strength lies in working as a team to engage with our clients and deliver first class customer service and the highest standards of excellence.</p>
{/* <ol>
<li>We strive to earn the confidence, respect, and loyalty of our customers. </li>
<li>We observe our customers’ work styles and listen closely to them, so that we understand their needs. </li>
<li>We help our customers grow their businesses. </li>
<li>We believe each customer’s needs are special and we collaborate with them to create and provide them solutions with high standard of quality. </li>
<li>We strive to exceed customers’ needs and expectations. </li>

</ol> */}
        </div>
       
        <div className='home-service-box'>
            <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCheck></FaCheck></span>
          </div>
          <h3 className='home-service-title'>Professionalism</h3>
          <p>We exhibit the highest level of professionalism in all our dealings! We also comply with stringent Health and Safety regulations, which are essential both to our clients and the safety of our workforce.</p>
        {/* <ol>	
     <li> Our people are the heart and soul of Pinnacle Concepts.</li>
<li>We aim to combine our energy, knowledge, and talents daily to produce services and goods that exceed our customers’ needs. </li>
<li>Quality of our people would be the quality of our company. </li>
<li>We help each other and learn together. </li>
<li>We respect and depend on each other.</li> 
<li>We work hard.  We work smart.  We have fun! </li>
</ol> */}

        </div>
        <div className='home-service-box'>
          <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaUsers></FaUsers></span>
          </div>
          <h3 className='home-service-title'>Experienced Team</h3>
          <p>Our team is fully conversant with current planning laws and their experience and expert advice allows clients to make informed choices about the design of their projects.</p>
        {/* <ol>
<li>We welcome and embrace change.  </li>
<li>Change keeps us healthy and fosters innovations. </li>
<li>Change makes us forward-looking and agile in today’s changing market. </li>
<li>We believe in the power of learning.  Everyday we learn and grow. </li>
</ol> */}
        </div>
<div className='home-service-box'>
          <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCertificate></FaCertificate></span>
          </div>
          <h3 className='home-service-title'>Certifications</h3>
          <p>
          As a company we are registered with the relevant authorities and our entire workforce holds the appropriate qualifications and certifications.
            </p>
{/* <ol>
<li>We look for smart ways to work.  Effectiveness of our people is the only way that we are able to exceed our customer’s expectations. </li>
<li>We aim to simplify our business processes, manage complexity and eliminate waste. </li>
<li>We promote the wise use and preservation of our resources. </li>
<li>We encourage leadership from our people to identify and create solutions to many opportunities that face our business. </li>
</ol> */}
        </div>

 

    </div>
        


        </div>


    </div>



  </section> 
      <section className='section-cover who-we-are-cover' >
     
      {/* <p className='about-intro-p'></p> */}
          <div className='content-container vm-container'>
                 <div className='vision-mission-content'>
                   <article className='vision-mission-info'>
                      
                          <h3 className='title-h3'>Our Vision</h3>
                          
                           <div className='vm-line'></div>
                          <p>To be a company where people are inspired to be the best they can be while providing quality products and services that adhere to international standards.</p>
                          
                          <h3 className='title-h3'>Our Mission</h3>
                          <div className='vm-line'></div>
                          <p>To listen to our customers, meet their needs and lead in the market of ICT Equipment in East Africa.</p>

                    </article>

                    <article className='vision-mission-info'>
                      
                          <h3 className='title-h3'>Core Values</h3>
                          
                           <div className='vm-line'></div>
                           <ul className='cv-ul'>
                            <li>Respect.</li>
                            <li>Integrity.</li>
                            <li>Teamwork.</li>
                            <li>Quality.</li>
                            <li>Humility.</li>
                            <li>Creativity.</li>
                           </ul>
                          {/* <p>To be a world-class and global manufacturer and distributor of office and institutional supplies.</p>
                          
                          <h3 className='title-h3'>Our Mission</h3>
                          <div className='vm-line'></div>
                          <p>To produce and deliver world class quality products and provide excellent and reliable services to all our customers.</p> */}

                    </article>

                    


                 </div>

                 {/* <div className='vision-mission-content mission-info'>

                  <article className='vision-mission-slides'>
                         
                        <MissionCarousel></MissionCarousel>
                    </article>

                    <article className='vision-mission-info vision-mission-info1'>
                      
                          <h3 className='title-h3'>Our Core Values</h3>
                          
                           <div className='vm-line'></div>
                          <ul>
                            <li>Customer Satisfaction.</li>
                            <li>Communication.</li>
                            <li>Ethical Practices & Honesty.</li>
                            <li>Quality Products & Safe Environment.</li>
                            <li>Appreciation & Recognition of our People.</li>
                            <li>Profitable & Growing Business.</li>
                          </ul>
                          
                          

                    </article>

                    <article className='vision-mission-info '>
<h3 className='title-h3'>Core Values</h3>
<div className='vm-line'></div>
           <CoreValues></CoreValues>

                    </article>


                 </div> */}
          </div>
    </section>
  
    </>
  )
}

export default Home