
import React, {useEffect} from 'react';

import {FaArrowRight, FaTimes} from 'react-icons/fa';



const Team = () => {
    useEffect(()=>{
document.title = "Projector Screens | Fordwich Company Limited";
},[]);


  return (
    <section className='section-cover team-cover equip-cover' >
         <div className='content-container products-container'>
          
          <h2 className="home-h2 home-service-h2">Projector Screens</h2>
             {/* <p className='about-intro-p'>Exotic Furniture</p> */}
         
            <div className='services-container-new'>
              <article className='single-service-new'>
    
              <div className='tools-slides-cover'>
                {/* <Tool1></Tool1> */}
                <img src='./images/46.jpg' class='item-image '></img>
              </div>
            {/* <h3 className='skill-h3 service-h3-new'>PC/HF/SS/001</h3> */}
          </article>
          <article className='single-service-new'>
    
              <div className='tools-slides-cover'>
                {/* <Tool1></Tool1> */}
                <img src='./images/47.jpg' class='item-image '></img>
              </div>
            {/* <h3 className='skill-h3 service-h3-new'>PC/HF/SS/002</h3> */}
          </article>
          <article className='single-service-new'>
    
              <div className='tools-slides-cover'>
                {/* <Tool1></Tool1> */}
                <img src='./images/48.jpg' class='item-image '></img>
              </div>
            {/* <h3 className='skill-h3 service-h3-new'>PC/HF/SS/003</h3> */}
          </article>
          <article className='single-service-new'>
    
              <div className='tools-slides-cover'>
                {/* <Tool1></Tool1> */}
                <img src='./images/49.jpg' class='item-image '></img>
              </div>
            {/* <h3 className='skill-h3 service-h3-new'>PC/HF/SS/004</h3> */}
          </article>
                
                
      </div>
         </div>
    </section>
  )
}

export default Team