
import React, {useEffect, useState} from 'react';

import {FaArrowRight, FaTimes} from 'react-icons/fa';



const Team = () => {
    useEffect(()=>{
document.title = "Smart Boards | Fordwich Company Limited";
},[]);


  return (
    <section className='section-cover team-cover equip-cover' >
         <div className='content-container products-container'>
          
          <h2 className="home-h2 home-service-h2">Smart Boards</h2>
             {/* <p className='about-intro-p'>Exotic Furniture</p> */}
         
            <div className='services-container-new'>
              <article className='single-service-new'>  
              <div className='tools-slides-cover'>
               
                <img src='./images/85.jpg' class='item-image'></img>
              </div>
             </article>
              <article className='single-service-new'>  
              <div className='tools-slides-cover'>
                {/* <Tool1></Tool1> */}
                <img src='./images/81.jpg' class='item-image'></img>
              </div>
             </article>
             <article className='single-service-new'>  
              <div className='tools-slides-cover'>
                {/* <Tool1></Tool1> */}
                <img src='./images/79.jpg' class='item-image'></img>
              </div>
             </article>
             <article className='single-service-new'>  
              <div className='tools-slides-cover'>
                {/* <Tool1></Tool1> */}
                <img src='./images/80.jpg' class='item-image'></img>
              </div>
             </article>
              <article className='single-service-new'>
    
              <div className='tools-slides-cover'>
                {/* <Tool1></Tool1> */}
                <img src='./images/34.jpg' class='item-image'></img>
              </div>
            
          </article>
          
          <article className='single-service-new'>
    
              <div className='tools-slides-cover'>
                {/* <Tool1></Tool1> */}
                <img src='./images/35.jpg' class='item-image'></img>
              </div>
    
          </article>
          <article className='single-service-new'>
    
              <div className='tools-slides-cover'>
                {/* <Tool1></Tool1> */}
                <img src='./images/36.jpg' class='item-image'></img>
              </div>
            {/* <h3 className='skill-h3 service-h3-new'>PC/OF/OCB/003</h3> */}
          </article>
                <article className='single-service-new'>
    
              <div className='tools-slides-cover'>
                {/* <Tool1></Tool1> */}
                <img src='./images/37.jpg' class='item-image'></img>
              </div>
            {/* <h3 className='skill-h3 service-h3-new'>PC/OF/OCB/004</h3> */}
          </article>
              
        
      </div>
         </div>
    </section>
  )
}

export default Team