
import React, {useEffect, useState} from 'react';

import {FaArrowRight, FaTimes} from 'react-icons/fa';



const Team = () => {
    useEffect(()=>{
document.title = "Software | Fordwich Company Limited";
},[]);


  return (
    <section className='section-cover team-cover equip-cover' >
         <div className='content-container products-container'>
          
          <h2 className="home-h2 home-service-h2">Software (Accounting & Microsoft Software)</h2>
             {/* <p className='about-intro-p'>Exotic Furniture</p> */}
         
            <div className='services-container-new'>
              <article className='single-service-new'>
    
              <div className='tools-slides-cover'>
                {/* <Tool1></Tool1> */}
                <img src='./images/1.png' class='item-image item-image-a'></img>
              </div>
            {/* <h3 className='skill-h3 service-h3-new'>Office Desks</h3> */}
          </article>
               <article className='single-service-new'>
    
              <div className='tools-slides-cover'>
                {/* <Tool1></Tool1> */}
                <img src='./images/87.jpg' class='item-image item-image-a'></img>
              </div>
            {/* <h3 className='skill-h3 service-h3-new'>Office Desks</h3> */}
          </article>
           <article className='single-service-new'>
    
              <div className='tools-slides-cover'>
                {/* <Tool1></Tool1> */}
                <img src='./images/88.jpg' class='item-image item-image-a'></img>
              </div>
            {/* <h3 className='skill-h3 service-h3-new'>Office Desks</h3> */}
          </article>
               
          <article className='single-service-new'>
    
              <div className='tools-slides-cover'>
                {/* <Tool1></Tool1> */}
                <img src='./images/89.jpg' class='item-image item-image-a'></img>
              </div>
            {/* <h3 className='skill-h3 service-h3-new'>Office Desks</h3> */}
          </article>
                <article className='single-service-new'>
    
              <div className='tools-slides-cover'>
                {/* <Tool1></Tool1> */}
                <img src='./images/50.jpg' class='item-image item-image-a'></img>
              </div>
            {/* <h3 className='skill-h3 service-h3-new'>Office Desks</h3> */}
          </article>
                <article className='single-service-new'>
    
              <div className='tools-slides-cover'>
                {/* <Tool1></Tool1> */}
                <img src='./images/51.jpg' class='item-image item-image-a'></img>
              </div>
            {/* <h3 className='skill-h3 service-h3-new'>Office Desks</h3> */}
          </article>
          <article className='single-service-new'>
    
              <div className='tools-slides-cover'>
                {/* <Tool1></Tool1> */}
                <img src='./images/52.jpg' class='item-image item-image-a'></img>
              </div>
            {/* <h3 className='skill-h3 service-h3-new'>Office Desks</h3> */}
          </article>
         
      </div>
         </div>
    </section>
  )
}

export default Team