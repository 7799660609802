
import React, {useEffect, useState} from 'react';

import {FaArrowRight, FaTimes} from 'react-icons/fa';



const Team = () => {
    useEffect(()=>{
document.title = "Printers | Fordwich Company Limited";
},[]);


  return (
    <section className='section-cover team-cover equip-cover' >
         <div className='content-container products-container'>
          
          <h2 className="home-h2 home-service-h2">Printers</h2>
             {/* <p className='about-intro-p'>Exotic Furniture</p> */}
         
            <div className='services-container-new'>
              <article className='single-service-new'>
    
              <div className='tools-slides-cover'>
                {/* <Tool1></Tool1> */}
                <img src='./images/76.jpg' class='item-image '></img>
              </div>
            {/* <h3 className='skill-h3 service-h3-new'>PC/OF/OS/001</h3> */}
          </article>
          <article className='single-service-new'>
    
              <div className='tools-slides-cover'>
                {/* <Tool1></Tool1> */}
                <img src='./images/77.png' class='item-image '></img>
              </div>
            {/* <h3 className='skill-h3 service-h3-new'>PC/OF/OS/001</h3> */}
          </article>
          <article className='single-service-new'>
    
              <div className='tools-slides-cover'>
                {/* <Tool1></Tool1> */}
                <img src='./images/75.jpg' class='item-image '></img>
              </div>
            {/* <h3 className='skill-h3 service-h3-new'>PC/OF/OS/001</h3> */}
          </article>
              <article className='single-service-new'>
    
              <div className='tools-slides-cover'>
                {/* <Tool1></Tool1> */}
                <img src='./images/16.jpg' class='item-image '></img>
              </div>
            {/* <h3 className='skill-h3 service-h3-new'>PC/OF/OS/001</h3> */}
          </article>    
          <article className='single-service-new'>
         
              <div className='tools-slides-cover'>
                {/* <Tool2></Tool2> */}
                <img src='./images/19.jpg' class='item-image'></img>
              </div>
            {/* <h3 className='skill-h3 service-h3-new'>PC/OF/OS/004</h3> */}
          </article>
          <article className='single-service-new'>
         
              <div className='tools-slides-cover'>
                {/* <Tool2></Tool2> */}
                <img src='./images/20.jpg' class='item-image'></img>
              </div>
            {/* <h3 className='skill-h3 service-h3-new'>PC/OF/OS/005</h3> */}
          
          </article>
          <article className='single-service-new'>
         
              <div className='tools-slides-cover'>
                {/* <Tool2></Tool2> */}
                <img src='./images/21.jpg' class='item-image'></img>
              </div>
            {/* <h3 className='skill-h3 service-h3-new'>PC/OF/OS/006</h3> */}
          
          </article>
          <article className='single-service-new'>
    
              <div className='tools-slides-cover'>
                {/* <Tool1></Tool1> */}
                <img src='./images/17.jpg' class='item-image'></img>
              </div>
            {/* <h3 className='skill-h3 service-h3-new'>PC/OF/OS/002</h3> */}
          </article>
          <article className='single-service-new'>
    
              <div className='tools-slides-cover'>
                {/* <Tool1></Tool1> */}
                <img src='./images/18.jpg' class='item-image'></img>
              </div>
            {/* <h3 className='skill-h3 service-h3-new'>PC/OF/OS/003</h3> */}
          </article>   
      </div>
         </div>
    </section>
  )
}

export default Team